<script lang="ts">
  import { goto } from "$app/navigation";
  import { page } from "$app/stores";

  import ky from "ky";
  import { Home, LogOut, Settings, Wrench } from "lucide-svelte";

  import { makeErrorToast } from "$lib/toast";
  import Dropdown from "$lib/ui/system/Dropdown.svelte";
  import DropdownItem from "$lib/ui/system/DropdownItem.svelte";
  import DropdownItemGroup from "$lib/ui/system/DropdownItemGroup.svelte";
  import Loading from "$lib/ui/system/Loading.svelte";
  import Avatar from "$lib/ui/system/avatar/Avatar.svelte";
  import { mutate, user } from "$lib/user";

  async function logout() {
    try {
      await ky.post("/api/logout", { json: {} });
    } catch (error: any) {
      makeErrorToast(error);
    }
    mutate();
    await goto("/");
  }
</script>

{#if $user && $user?.logged_in}
  <Dropdown align="right">
    <div slot="button" class="cursor-pointer">
      <Avatar
        avatarUrl={$user?.avatar_url}
        size={28}
        name={$user?.notification_email}
      />
    </div>

    <div class="w-64" />

    {#if $user.approved}
      <DropdownItemGroup>
        {#if $user.is_admin}
          {#if $page.data.isInternal}
            <DropdownItem href="/home">
              <Home size={20} class="text-zinc-400" slot="icon" />
              Dashboard
            </DropdownItem>
          {:else}
            <DropdownItem href="/internal">
              <Wrench size={20} class="text-zinc-400" slot="icon" />
              Internal Tools
            </DropdownItem>
          {/if}
        {/if}
        <DropdownItem href="/settings">
          <Settings size={20} class="text-zinc-400" slot="icon" />
          Settings
        </DropdownItem>
      </DropdownItemGroup>
      <hr />
    {/if}
    <DropdownItemGroup>
      <DropdownItem on:click={logout}>
        <LogOut size={20} class="text-zinc-400" slot="icon" />
        Logout
      </DropdownItem>
    </DropdownItemGroup>
  </Dropdown>
{:else}
  <Loading />
{/if}
